<template>
  <div class="storage-page">
    <PageHeader :items="items" />
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col lg="4">
            <div class="storage-card">
              <input type="radio" class="storage-input" name="storage" id="storage1">
              <label for="storage1" class="storage-label">
                <div class="tag"></div>
                <h3>5 GB</h3>
                <p>0 {{$t("Credits")}} / {{$t("Free")}}</p>
              </label>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="storage-card">
              <input type="radio" class="storage-input" name="storage" id="storage2">
              <label for="storage2" class="storage-label">
                <div class="tag"></div>
                <h3>10 GB</h3>
                <p>2 {{$t("Credits")}} / {{$t("Free")}}</p>
              </label>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="storage-card">
              <input type="radio" class="storage-input" name="storage" id="storage3">
              <label for="storage3" class="storage-label">
                <div class="tag"></div>
                <h3>25 GB</h3>
                <p>3 {{$t("Credits")}} / {{$t("Free")}}</p>
              </label>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="storage-card">
              <input type="radio" class="storage-input" name="storage" id="storage4">
              <label for="storage4" class="storage-label">
                <div class="tag"></div>
                <h3>50 GB</h3>
                <p>4 {{$t("Credits")}} / {{$t("Free")}}</p>
              </label>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="storage-card">
              <input type="radio" class="storage-input" name="storage" id="storage5">
              <label for="storage5" class="storage-label">
                <div class="tag"></div>
                <h3>100 GB</h3>
                <p>5 {{$t("Credits")}} / {{$t("Free")}}</p>
              </label>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="storage-card">
              <input type="radio" class="storage-input" name="storage" id="storage6">
              <label for="storage6" class="storage-label">
                <div class="tag"></div>
                <h3>250 GB</h3>
                <p>6 {{$t("Credits")}} / {{$t("Free")}}</p>
              </label>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BRow, BCol } from "bootstrap-vue";
import Card from "@/components/elements/Card.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
export default {
  components: {
    Card,
    BLink,
    BRow,
    BCol,
    ElementIcon,
    TextInput,
    PageHeader
  },
  computed: {
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Storage"),
          active: true,
        },
      ];
    },
  },
};
</script>

<style></style>
